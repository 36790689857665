/**
 * Fuji color palette
 * @see https://git.ouryahoo.com/pages/fuji-design/fuji-docs/app/colorpalette.html
 */
:root,
html[data-color-scheme=dark] [data-maintain-color],
html[data-color-theme-enabled] [data-color-scheme=light],
html[data-color-theme-enabled][data-color-scheme=light] {
  --barbie: #f80e5d;
  --barney: #cc008c;
  --batcave: #232a31;
  --battleship: #5b636a;
  --black: #000;
  --blurple: #5d5eff;
  --bob: #b0b9c1;
  --bonsai: #00873c;
  --canary: #ffde00;
  --carrot-juice: #ff520d;
  --charcoal: #464e56;
  --cheetos: #ff6e0c;
  --cobalt: #003abc;
  --denim: #1a0dab;
  --dirty-seagull: #e0e4e9;
  --dolphin: #6e7780;
  --dory: #0f69ff;
  --gandalf: #979ea8;
  --grape-jelly: #6001d2;
  --grey-hair: #f0f3f5;
  --hendrix: #f8f4ff;
  --hulk-pants: #7e1fff;
  --inkwell: #1d2228;
  --kiwi: #00d15e;
  --malbec: #39007d;
  --malibu: #ff0080;
  --marshmallow: #f5f8fa;
  --masala: #ff8b12;
  --midnight: #101518;
  --mimosa: #ffd333;
  --mulah: #1ac567;
  --mulberry: #5015B0;
  --ninja-turtle: #00ab5e;
  --pebble: #c7cdd2;
  --peeps: #7dcbff;
  --playdoh: #21d87d;
  --ramones: #2c363f;
  --sapphire: #2f0060;
  --scooter: #0063eb;
  --sea-foam: #11d3cd;
  --shark: #828a93;
  --sky: #12a9ff;
  --smurfette: #188fff;
  --solo-cup: #eb0f29;
  --spirulina: #009c94;
  --starfish: #7759ff;
  --swedish-fish: #ff333a;
  --thanos: #907cff;
  --turmeric: #ffa700;
  --watermelon: #ff4d52;
  --white: #fff;
  /* rbg colors for shadows */
  --rgb-batcave: 35, 42, 49;
  --rgb-black: 0, 0, 0;
  --rgb-dory: 15, 105, 255;
  --rgb-dirty-seagull: 224, 228, 233;
  --rgb-grape-jelly: 96, 1, 210;
  --rgb-grey-hair: 240, 243, 245;
  --rgb-inkwell: 29, 34, 40;
  --rgb-white: 255, 255, 255;
  /* shadows */
  --elevation-1: 0 1px 0 rgba(var(--rgb-dirty-seagull), 1);
  --elevation-2: 0 0 1px rgba(var(--rgb-black), 0.1), 0 2px 4px rgba(var(--rgb-black), 0.08);
  --elevation-3: 0 0 1px rgba(var(--rgb-black), 0.08), 0 4px 8px rgba(var(--rgb-black), 0.1);
  --elevation-4: 0 0 2px rgba(var(--rgb-black), 0.05), 0 4px 16px rgba(var(--rgb-black), 0.2);
  --elevation-5: 0 0 16px rgba(var(--rgb-black), 0.05), 0 32px 32px -20px rgba(var(--rgb-black), 0.4);
  /* partner colors */
  --att-blue: #0057b8;
  --rogers-red: #c41522;
  --frontier-red: #c41230;
}

/* client side toggle */
html[data-color-theme-enabled][data-color-scheme=dark],
html[data-color-theme-enabled] [data-color-scheme=dark] {
  --barney: #ff0080;
  --batcave: #f0f3f5;
  --battleship: #b0b9c1;
  --black: #fff;
  --blurple: #907cff;
  --bob: #6e7780;
  --bonsai: #1ac567;
  --carrot-juice: #ff6e0c;
  --charcoal: #b0b9c1;
  --cobalt: #12a9ff;
  --denim: #12a9ff;
  --dirty-seagull: #464e56;
  --dolphin: #b0b9c1;
  --dory: #12a9ff;
  --gandalf: #828a93;
  --grape-jelly: #907cff;
  --grey-hair: #232a31;
  --hendrix: #907cff;
  --hulk-pants: #907cff;
  --inkwell: #fff;
  --malbec: #907cff;
  --marshmallow: #232a31;
  --midnight: #fff;
  --mulberry: #907cff;
  --pebble: #5b636a;
  --ramones: #b0b9c1;
  --scooter: #12a9ff;
  --shark: #b0b9c1;
  --solo-cup: #ff4d52;
  --starfish: #907cff;
  --white: #1d2228;
  /* rbg colors for shadows, note black not updated as used for elevations */
  --rgb-batcave: 35, 42, 49;
  --rgb-dory: 70, 78, 86;
  --rgb-dirty-seagull: 224, 228, 233;
  --rgb-grape-jelly: 144, 124, 255;
  --rgb-grey-hair: 44, 54, 63;
  --rgb-inkwell: 255, 255, 255;
  --rgb-white: 29, 34, 40;
  /* shadows */
  --elevation-1: 0 1px 0 rgba(var(--rgb-black), 1), 0 0 1px rgba(var(--rgb-black), 0.85);
  --elevation-2: 0 0 1px rgba(var(--rgb-black), 0.9), 0 2px 4px rgba(var(--rgb-black), 0.92);
  --elevation-3: 0 0 1px rgba(var(--rgb-black), 0.92), 0 4px 8px rgba(var(--rgb-black), 0.9);
  --elevation-4: 0 0 2px rgba(var(--rgb-black), 0.95), 0 4px 16px rgba(var(--rgb-black), 0.8);
  --elevation-5: 0 0 16px rgba(var(--rgb-black), 0.95), 0 32px 32px -20px rgba(var(--rgb-black), 0.6);
  /* browser chrome */
  color-scheme: dark;
  /* FF scrollbar */
  scrollbar-color: #000 #2c363f;
  /* partner colors */
  --att-blue: #0057b8;
  --rogers-red: #c41522;
  --frontier-red: #c41230;
}

/* OS level */
@media (prefers-color-scheme: dark) {
  html[data-color-theme-enabled] {
    --barney: #ff0080;
    --batcave: #f0f3f5;
    --battleship: #b0b9c1;
    --black: #fff;
    --blurple: #907cff;
    --bob: #6e7780;
    --bonsai: #1ac567;
    --carrot-juice: #ff6e0c;
    --charcoal: #b0b9c1;
    --cobalt: #12a9ff;
    --denim: #12a9ff;
    --dirty-seagull: #464e56;
    --dolphin: #b0b9c1;
    --dory: #12a9ff;
    --gandalf: #828a93;
    --grape-jelly: #907cff;
    --grey-hair: #232a31;
    --hendrix: #907cff;
    --hulk-pants: #907cff;
    --inkwell: #fff;
    --malbec: #907cff;
    --marshmallow: #232a31;
    --midnight: #fff;
    --mulberry: #907cff;
    --pebble: #5b636a;
    --ramones: #b0b9c1;
    --scooter: #12a9ff;
    --shark: #b0b9c1;
    --solo-cup: #ff4d52;
    --starfish: #907cff;
    --white: #1d2228;
    /* rbg colors for shadows, note black not updated as used for elevations */
    --rgb-batcave: 35, 42, 49;
    --rgb-dory: 70, 78, 86;
    --rgb-dirty-seagull: 224, 228, 233;
    --rgb-grape-jelly: 144, 124, 255;
    --rgb-grey-hair: 44, 54, 63;
    --rgb-inkwell: 255, 255, 255;
    --rgb-white: 29, 34, 40;
    /* shadows */
    --elevation-1: 0 1px 0 rgba(var(--rgb-black), 1), 0 0 1px rgba(var(--rgb-black), 0.85);
    --elevation-2: 0 0 1px rgba(var(--rgb-black), 0.9), 0 2px 4px rgba(var(--rgb-black), 0.92);
    --elevation-3: 0 0 1px rgba(var(--rgb-black), 0.92), 0 4px 8px rgba(var(--rgb-black), 0.9);
    --elevation-4: 0 0 2px rgba(var(--rgb-black), 0.95), 0 4px 16px rgba(var(--rgb-black), 0.8);
    --elevation-5: 0 0 16px rgba(var(--rgb-black), 0.95), 0 32px 32px -20px rgba(var(--rgb-black), 0.6);
    /* browser chrome */
    color-scheme: dark;
    /* FF scrollbar */
    scrollbar-color: #000 #2c363f;
    /* partner colors */
    --att-blue: #0057b8;
    --rogers-red: #c41522;
    --frontier-red: #c41230;
  }
  html[data-color-theme-enabled] [data-maintain-color] {
    --barbie: #f80e5d;
    --barney: #cc008c;
    --batcave: #232a31;
    --battleship: #5b636a;
    --black: #000;
    --blurple: #5d5eff;
    --bob: #b0b9c1;
    --bonsai: #00873c;
    --canary: #ffde00;
    --carrot-juice: #ff520d;
    --charcoal: #464e56;
    --cheetos: #ff6e0c;
    --cobalt: #003abc;
    --denim: #1a0dab;
    --dirty-seagull: #e0e4e9;
    --dolphin: #6e7780;
    --dory: #0f69ff;
    --gandalf: #979ea8;
    --grape-jelly: #6001d2;
    --grey-hair: #f0f3f5;
    --hendrix: #f8f4ff;
    --hulk-pants: #7e1fff;
    --inkwell: #1d2228;
    --kiwi: #00d15e;
    --malbec: #39007d;
    --malibu: #ff0080;
    --marshmallow: #f5f8fa;
    --masala: #ff8b12;
    --midnight: #101518;
    --mimosa: #ffd333;
    --mulah: #1ac567;
    --mulberry: #5015B0;
    --ninja-turtle: #00ab5e;
    --pebble: #c7cdd2;
    --peeps: #7dcbff;
    --playdoh: #21d87d;
    --ramones: #2c363f;
    --sapphire: #2f0060;
    --scooter: #0063eb;
    --sea-foam: #11d3cd;
    --shark: #828a93;
    --sky: #12a9ff;
    --smurfette: #188fff;
    --solo-cup: #eb0f29;
    --spirulina: #009c94;
    --starfish: #7759ff;
    --swedish-fish: #ff333a;
    --thanos: #907cff;
    --turmeric: #ffa700;
    --watermelon: #ff4d52;
    --white: #fff;
    /* rbg colors for shadows */
    --rgb-batcave: 35, 42, 49;
    --rgb-black: 0, 0, 0;
    --rgb-dory: 15, 105, 255;
    --rgb-dirty-seagull: 224, 228, 233;
    --rgb-grape-jelly: 96, 1, 210;
    --rgb-grey-hair: 240, 243, 245;
    --rgb-inkwell: 29, 34, 40;
    --rgb-white: 255, 255, 255;
    /* shadows */
    --elevation-1: 0 1px 0 rgba(var(--rgb-dirty-seagull), 1);
    --elevation-2: 0 0 1px rgba(var(--rgb-black), 0.1), 0 2px 4px rgba(var(--rgb-black), 0.08);
    --elevation-3: 0 0 1px rgba(var(--rgb-black), 0.08), 0 4px 8px rgba(var(--rgb-black), 0.1);
    --elevation-4: 0 0 2px rgba(var(--rgb-black), 0.05), 0 4px 16px rgba(var(--rgb-black), 0.2);
    --elevation-5: 0 0 16px rgba(var(--rgb-black), 0.05), 0 32px 32px -20px rgba(var(--rgb-black), 0.4);
    /* partner colors */
    --att-blue: #0057b8;
    --rogers-red: #c41522;
    --frontier-red: #c41230;
  }
}
